import { render, staticRenderFns } from "./BrokerFormNew.vue?vue&type=template&id=392b3425"
import script from "./BrokerFormNew.vue?vue&type=script&lang=js"
export * from "./BrokerFormNew.vue?vue&type=script&lang=js"
import style0 from "./BrokerFormNew.vue?vue&type=style&index=0&id=392b3425&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports