<template>
  <div>
    <form-wizard
      color="#F8AC18"
      :title="null"
      :subtitle="null"
      shape="square"
      layout="vertical"
      finish-button-text="Enviar"
      back-button-text="Anterior"
      next-button-text="Próximo"
      class="vertical-steps steps-transparent mb-3"
      @on-complete="formSubmitted">
      <!-- accoint details tab -->
      <tab-content title="Etapa" :before-change="validationForm">
        <validation-observer ref="accountRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Etapa 1</h5>
              <small class="text-muted"> Insira os detalhes da conta </small>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Corretora ou imobiliaria"
                label-for="corimob">
                <validation-provider
                  #default="{ errors }"
                  name="corimob"
                  rules="required">
                  <b-form-select
                    id="corimob"
                    v-model="broker.selectedTipo"
                    :options="tipoOption"
                    @change="changeLabel" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group :label="labelNome" label-for="nome">
                <validation-provider
                  #default="{ errors }"
                  name="nome"
                  rules="required">
                  <b-form-input
                    id="nome"
                    v-model="broker.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Jõao Silva" />
                  <!-- <v-select
                    id="corr_imob"
                    v-model="selectedTipo"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tipoOption"
                    :selectable="option => ! option.value.includes('select_value')"
                    label="text"
                  /> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group label="Celular" label-for="celular">
                <validation-provider
                  #default="{ errors }"
                  name="celular"
                  rules="required">
                  <b-form-input
                    id="celular"
                    v-model="broker.phoneNumber"
                    v-mask="['(##) #####-####']"
                    :state="errors.length > 0 ? false : null"
                    placeholder="(99) 99999-9999" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="datanasc" label="Data de Nascimento">
                <validation-provider
                  #default="{ errors }"
                  name="datanasc"
                  rules="required">
                  <flat-pickr
                    v-model="broker.datanasc"
                    :config="config"
                    class="form-control"
                    name="datanasc"
                    placeholder="Data de Nascimento" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Tipo de Documento" label-for="tipo_doc">
                <validation-provider
                  #default="{ errors }"
                  name="tipo_doc"
                  rules="required">
                  <!-- <b-form-input
                    id="name"
                    v-model="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="johndoe"
                  /> -->
                  <b-form-select
                    id="tipo_doc"
                    v-model="broker.selectedTipoDoc"
                    :options="tipoDoc"
                    @change="changeDoc" />
                  <!-- <v-select
                    id="corr_imob"
                    v-model="broker.selectedTipoDoc"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tipoDoc"
                    :selectable="option => ! option.value.includes('select_value')"
                    label="text"
                    @change="changeDoc"
                  /> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Documento de Identificação"
                label-for="doc_id">
                <validation-provider
                  #default="{ errors }"
                  name="doc_id"
                  rules="required">
                  <b-form-input
                    id="doc_id"
                    v-model="broker.doc_id"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe" />
                  <!-- <v-select
                    id="corr_imob"
                    v-model="selectedTipo"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tipoOption"
                    :selectable="option => ! option.value.includes('select_value')"
                    label="text"
                  /> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col md="6">
              <b-form-group
                label="Nome Completo"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="emailValue"
                    type="email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john.doe@email.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <b-col md="6">
              <b-form-group label="Senha" label-for="Senha">
                <validation-provider
                  #default="{ errors }"
                  name="Senha"
                  vid="Password"
                  rules="required|password">
                  <b-form-input
                    id="password"
                    v-model="PasswordValue"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Senha" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Confirmar Senha" label-for="c-password">
                <validation-provider
                  #default="{ errors }"
                  name="Confirmar Senha"
                  rules="required|confirmed:Password">
                  <b-form-input
                    id="c-password"
                    v-model="passwordCon"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Confirmar Senha" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details tab -->
      <tab-content title="Etapa" :before-change="validationFormInfo">
        <validation-observer ref="infoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Etapa 2</h5>
              <small class="text-muted">Insira os detalhes da conta</small>
            </b-col>
            <b-col v-if="corretor" md="6">
              <b-form-group label="Região de atuação" label-for="regiatuacao">
                <validation-provider
                  #default="{ errors }"
                  name="regiao-atuacao"
                  rules="required">
                  <b-form-input
                    id="regiatuacao"
                    v-model="broker.regiatu"
                    placeholder="..."
                    :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="CRECI" label-for="creci">
                <validation-provider
                  #default="{ errors }"
                  name="Creci"
                  rules="required">
                  <b-form-input
                    id="creci"
                    v-model="broker.creci"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Doe" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Copia do CRECI" label-for="copia-creci">
                <validation-provider
                  #default="{ errors }"
                  name="copia-creci"
                  rules="required">
                  <b-form-file
                    id="copia-creci"
                    v-model="broker.cop_creci"
                    :state="errors.length > 0 ? false : null"
                    :accept="typeFiles"
                    placeholder="Copia do CRECI" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="corretor" md="6">
              <b-form-group :label="labeldoc" label-for="cop_doc">
                <validation-provider
                  #default="{ errors }"
                  name="cop_doc"
                  rules="required">
                  <b-form-file
                    id="cop_doc"
                    v-model="broker.cop_doc"
                    :state="errors.length > 0 ? false : null"
                    :accept="typeFiles"
                    placeholder="Cópia do Documento" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="corretor" md="6">
              <b-form-group :label="labelFotosua" label-for="foto_creci">
                <validation-provider
                  #default="{ errors }"
                  name="foto_creci"
                  rules="required">
                  <b-form-file
                    id="foto_creci"
                    v-model="broker.foto_doc"
                    :state="errors.length > 0 ? false : null"
                    :accept="typeFiles"
                    placeholder="Foto com CRECI" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="corretor" md="6">
              <b-form-group label="Foto sua com cartão CRECI" label-for="creci">
                <validation-provider
                  #default="{ errors }"
                  name="foto_creci"
                  rules="required">
                  <b-form-file
                    id="foto_creci"
                    v-model="broker.foto_doc"
                    :state="errors.length > 0 ? false : null"
                    :accept="typeFiles"
                    placeholder="Foto do documento aqui" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="imobiliaria" md="6">
              <b-form-group label="Cartão CNPJ" label-for="carcnpj">
                <validation-provider
                  #default="{ errors }"
                  name="carcnpj"
                  rules="required">
                  <b-form-file
                    id="carcnpj"
                    v-model="broker.cartaoCNPJ"
                    :state="errors.length > 0 ? false : null"
                    :accept="typeFiles"
                    placeholder="Cópia do Cartão CNPJ" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- address  -->
      <tab-content title="Etapa" :before-change="validationFormAddress">
        <validation-observer ref="addressRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Etapa 3</h5>
              <small class="text-muted">Insira os detalhes da conta</small>
            </b-col>
            <b-col md="4">
              <b-form-group label="Exposição" label-for="vi-nacional">
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="broker.concordaTermos"
                  name="checkbox-1">
                  Eu Aceito os
                  <b-link href="/termos" target="_blank">
                    Termos de Uso
                  </b-link>
                  &
                  <b-link href="/termos" target="_blank">
                    Políticas de Privacidade
                  </b-link>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
// import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
// import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BFormSelect,
  BLink,
  // BFormInvalidFeedback,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { codeIcon } from './code'
import dataTypeFiles from '../helper/dataTypeFiles'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BFormSelect,
    BLink,
    // vSelect,
    flatPickr,
    // BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      broker: {
        typeFiles: dataTypeFiles.types,
        selectedTipo: '',
        selectedTipoDoc: '',
        datanasc: '',
        regiatu: '',
        creci: '',
        cop_doc: '',
        foto_doc: '',
        concordaTermos: '',
        name: '',
        phoneNumber: '',
        cop_creci: '',
        cartaoCNPJ: '',
      },
      config: {
        dateFormat: 'd/m/Y',
      },
      emailValue: '',
      PasswordValue: '',
      passwordCon: '',
      landMark: '',
      pincode: '',
      city: '',
      required,
      email,
      codeIcon,
      corretor: false,
      imobiliaria: false,
      labelNome: 'Nome Completo',
      labeldoc: 'Documento',
      labelFotosua: 'Creci',
      tipoOption: [
        { value: 'PF', text: 'Corretor' },
        { value: 'PJ', text: 'Imobiliária' },
      ],
      tipoDoc: [{ value: 'cnpj', text: 'CNPJ' }],
      warning: 'Arquivo a ser anexado deve ter até 1MB',
    }
  },
  methods: {
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Form Submitted',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    changeLabel() {
      this.broker.selectedTipoDoc = ''
      if (this.broker.selectedTipo === 'PF') {
        this.labelNome = 'Nome Completo'
        this.tipoDoc = [
          { value: 'rg', text: 'RG' },
          { value: 'cnh', text: 'CNH' },
          { value: 'pass', text: 'Passaporte' },
        ]
        this.corretor = true
        this.imobiliaria = false
      }
      if (this.broker.selectedTipo === 'PJ') {
        this.labelNome = 'Razão Social'
        this.tipoDoc = [{ value: 'cnpj', text: 'CNPJ' }]
        this.corretor = false
        this.imobiliaria = true
      }
    },
    changeDoc() {
      const doc = this.broker.selectedTipoDoc
      let lb = ''
      if (doc === 'rg') {
        lb = 'RG'
      }
      if (doc === 'cnh') {
        lb = 'CNH'
      }
      if (doc === 'pass') {
        lb = 'Passaporte'
      }
      if (doc === 'cnpj') {
        lb = 'CNPJ'
      }
      this.labeldoc = `Cópia do ${lb}`
      this.labelFotosua = `Foto sua com ${lb}`
    },

    checkSizeFile(event) {
      const file = event.target.files[0]

      if (file.size > 1024 * 1024) {
        return
      }

      // eslint-disable-next-line consistent-return
      return 1
    },

    // warning
    msgWarning() {
      this.$swal({
        icon: 'info',
        title: 'Atenção',
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        html: `${this.warning}
            <br> <br> <br>
            <button type="button" variant="secondary" class="btn btn-secondary btn-no swl-cstm-btn-no-jst-prceed">Fechar</button>`,
        showCancelButton: false,
        onBeforeOpen: () => {
          const no = document.querySelector('.btn-no')

          no.addEventListener('click', () => {
            this.$swal.close()
          })
        },
      })
    },

    clearFiles(event) {
      this.$refs[event.target.id].reset()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
