var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"vertical-steps steps-transparent mb-3",attrs:{"color":"#F8AC18","title":null,"subtitle":null,"shape":"square","layout":"vertical","finish-button-text":"Enviar","back-button-text":"Anterior","next-button-text":"Próximo"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":"Etapa","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Etapa 1")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Insira os detalhes da conta ")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Corretora ou imobiliaria","label-for":"corimob"}},[_c('validation-provider',{attrs:{"name":"corimob","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"corimob","options":_vm.tipoOption},on:{"change":_vm.changeLabel},model:{value:(_vm.broker.selectedTipo),callback:function ($$v) {_vm.$set(_vm.broker, "selectedTipo", $$v)},expression:"broker.selectedTipo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{attrs:{"label":_vm.labelNome,"label-for":"nome"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nome","state":errors.length > 0 ? false : null,"placeholder":"Jõao Silva"},model:{value:(_vm.broker.name),callback:function ($$v) {_vm.$set(_vm.broker, "name", $$v)},expression:"broker.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":"Celular","label-for":"celular"}},[_c('validation-provider',{attrs:{"name":"celular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) #####-####']),expression:"['(##) #####-####']"}],attrs:{"id":"celular","state":errors.length > 0 ? false : null,"placeholder":"(99) 99999-9999"},model:{value:(_vm.broker.phoneNumber),callback:function ($$v) {_vm.$set(_vm.broker, "phoneNumber", $$v)},expression:"broker.phoneNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"datanasc","label":"Data de Nascimento"}},[_c('validation-provider',{attrs:{"name":"datanasc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.config,"name":"datanasc","placeholder":"Data de Nascimento"},model:{value:(_vm.broker.datanasc),callback:function ($$v) {_vm.$set(_vm.broker, "datanasc", $$v)},expression:"broker.datanasc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Tipo de Documento","label-for":"tipo_doc"}},[_c('validation-provider',{attrs:{"name":"tipo_doc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"tipo_doc","options":_vm.tipoDoc},on:{"change":_vm.changeDoc},model:{value:(_vm.broker.selectedTipoDoc),callback:function ($$v) {_vm.$set(_vm.broker, "selectedTipoDoc", $$v)},expression:"broker.selectedTipoDoc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Documento de Identificação","label-for":"doc_id"}},[_c('validation-provider',{attrs:{"name":"doc_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"doc_id","state":errors.length > 0 ? false : null,"placeholder":"johndoe"},model:{value:(_vm.broker.doc_id),callback:function ($$v) {_vm.$set(_vm.broker, "doc_id", $$v)},expression:"broker.doc_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Senha","label-for":"Senha"}},[_c('validation-provider',{attrs:{"name":"Senha","vid":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","type":"password","state":errors.length > 0 ? false : null,"placeholder":"Senha"},model:{value:(_vm.PasswordValue),callback:function ($$v) {_vm.PasswordValue=$$v},expression:"PasswordValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Confirmar Senha","label-for":"c-password"}},[_c('validation-provider',{attrs:{"name":"Confirmar Senha","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"c-password","type":"password","state":errors.length > 0 ? false : null,"placeholder":"Confirmar Senha"},model:{value:(_vm.passwordCon),callback:function ($$v) {_vm.passwordCon=$$v},expression:"passwordCon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Etapa","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Etapa 2")]),_c('small',{staticClass:"text-muted"},[_vm._v("Insira os detalhes da conta")])]),(_vm.corretor)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Região de atuação","label-for":"regiatuacao"}},[_c('validation-provider',{attrs:{"name":"regiao-atuacao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"regiatuacao","placeholder":"...","state":errors.length > 0 ? false : null},model:{value:(_vm.broker.regiatu),callback:function ($$v) {_vm.$set(_vm.broker, "regiatu", $$v)},expression:"broker.regiatu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1078214887)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"CRECI","label-for":"creci"}},[_c('validation-provider',{attrs:{"name":"Creci","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"creci","state":errors.length > 0 ? false : null,"placeholder":"Doe"},model:{value:(_vm.broker.creci),callback:function ($$v) {_vm.$set(_vm.broker, "creci", $$v)},expression:"broker.creci"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Copia do CRECI","label-for":"copia-creci"}},[_c('validation-provider',{attrs:{"name":"copia-creci","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"copia-creci","state":errors.length > 0 ? false : null,"accept":_vm.typeFiles,"placeholder":"Copia do CRECI"},model:{value:(_vm.broker.cop_creci),callback:function ($$v) {_vm.$set(_vm.broker, "cop_creci", $$v)},expression:"broker.cop_creci"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.corretor)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.labeldoc,"label-for":"cop_doc"}},[_c('validation-provider',{attrs:{"name":"cop_doc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"cop_doc","state":errors.length > 0 ? false : null,"accept":_vm.typeFiles,"placeholder":"Cópia do Documento"},model:{value:(_vm.broker.cop_doc),callback:function ($$v) {_vm.$set(_vm.broker, "cop_doc", $$v)},expression:"broker.cop_doc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,99091021)})],1)],1):_vm._e(),(_vm.corretor)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.labelFotosua,"label-for":"foto_creci"}},[_c('validation-provider',{attrs:{"name":"foto_creci","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"foto_creci","state":errors.length > 0 ? false : null,"accept":_vm.typeFiles,"placeholder":"Foto com CRECI"},model:{value:(_vm.broker.foto_doc),callback:function ($$v) {_vm.$set(_vm.broker, "foto_doc", $$v)},expression:"broker.foto_doc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4029193845)})],1)],1):_vm._e(),(_vm.corretor)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Foto sua com cartão CRECI","label-for":"creci"}},[_c('validation-provider',{attrs:{"name":"foto_creci","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"foto_creci","state":errors.length > 0 ? false : null,"accept":_vm.typeFiles,"placeholder":"Foto do documento aqui"},model:{value:(_vm.broker.foto_doc),callback:function ($$v) {_vm.$set(_vm.broker, "foto_doc", $$v)},expression:"broker.foto_doc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1508870413)})],1)],1):_vm._e(),(_vm.imobiliaria)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Cartão CNPJ","label-for":"carcnpj"}},[_c('validation-provider',{attrs:{"name":"carcnpj","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"carcnpj","state":errors.length > 0 ? false : null,"accept":_vm.typeFiles,"placeholder":"Cópia do Cartão CNPJ"},model:{value:(_vm.broker.cartaoCNPJ),callback:function ($$v) {_vm.$set(_vm.broker, "cartaoCNPJ", $$v)},expression:"broker.cartaoCNPJ"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3557896392)})],1)],1):_vm._e()],1)],1)],1),_c('tab-content',{attrs:{"title":"Etapa","before-change":_vm.validationFormAddress}},[_c('validation-observer',{ref:"addressRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Etapa 3")]),_c('small',{staticClass:"text-muted"},[_vm._v("Insira os detalhes da conta")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Exposição","label-for":"vi-nacional"}},[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.broker.concordaTermos),callback:function ($$v) {_vm.$set(_vm.broker, "concordaTermos", $$v)},expression:"broker.concordaTermos"}},[_vm._v(" Eu Aceito os "),_c('b-link',{attrs:{"href":"/termos","target":"_blank"}},[_vm._v(" Termos de Uso ")]),_vm._v(" & "),_c('b-link',{attrs:{"href":"/termos","target":"_blank"}},[_vm._v(" Políticas de Privacidade ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }